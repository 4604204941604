import { getStripeUserSubscription } from '@/service/pay.service'
import { UserSubscription } from '@/types'
import useService, { useCachedService } from '@banyudu/use-service'
import useAuth0Auth from './useAuth0Auth'
import { useMemo } from 'react'

const skipableGetStripeUserSubscription = async (params?: {
  skip?: boolean
}) => {
  if (params?.skip) return null
  const res = await getStripeUserSubscription()
  return res
}

const _useSubscription = useService(
  skipableGetStripeUserSubscription,
  (params) => !params?.skip,
)
const _useCachedSubscription = useCachedService(
  skipableGetStripeUserSubscription,
  (params) => !params?.skip,
)

// const useSubscription = (): {
//   data: UserSubscription | null
//   loading: boolean
// } => {
//   const { isLogin } = useAuth0Auth()

//   const params = useMemo(() => {
//     if (!isLogin) return { skip: true }
//     return {}
//   }, [isLogin])

//   const { data: subscription, isValidating: loading } = _useSubscription(params)

//   return {
//     data: subscription ?? null,
//     loading,
//   }
// }

export const useCachedSubscription = (): {
  data: UserSubscription | null
  refresh: () => void
  loading: boolean
} => {
  const { isLogin } = useAuth0Auth()

  const params = useMemo(() => {
    if (!isLogin) return { skip: true }
    return {}
  }, [isLogin])

  const {
    data: subscription,
    isValidating: loading,
    refresh,
  } = _useCachedSubscription(params)

  return {
    data: subscription ?? null,
    loading,
    refresh,
  }
}

// export default useSubscription
