import { useContext, useEffect } from 'react'
import { AmplitudeContext } from '../contexts/amplitude'
import { useCachedMe } from './useMe'
import { useSearchParams } from 'next/navigation'
import { UTM_FIELDS } from '@/constants'

const useAmplitude = () => {
  const context = useContext(AmplitudeContext)

  const { data: profile } = useCachedMe()

  // save querystring utm_source to localStorage
  const query = useSearchParams()
  useEffect(() => {
    for (const utmKey of UTM_FIELDS) {
      const value = query?.get(utmKey)
      if (value) {
        localStorage.setItem(utmKey, value)
      }
    }
  }, [query])

  useEffect(() => {
    if (profile?.id) {
      context.setUserId(profile.id)
    }
  }, [profile, context])

  if (context === undefined)
    throw new Error(
      'useAmplitudeContext must be used within a AmplitudeContextProvider',
    )
  return context
}

export default useAmplitude
