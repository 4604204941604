'use client'

import axios from 'axios'
import { useEffect, createContext, useMemo } from 'react'
import {
  init,
  track,
  getUserId,
  setUserId as setAmplitudeUserId,
} from '@amplitude/analytics-browser'
import {
  isProduction,
  utcDate,
  utcDateTime,
  utcTime,
  whisper,
  noop,
} from '@/utils'
import {
  AMPLITUDE_USER_CREATE_TIME_KEY,
  AMPLITUDE_USER_ID_KEY,
  UTM_FIELDS,
} from '@/constants'
import dayjs from 'dayjs'
import useCommonEventTrackingParams from '@/hooks/useCommonEventTrackingParams'

const AMPLITUDE_API_KEY: string = process.env
  .NEXT_PUBLIC_AMPLITUDE_API_KEY as string

let usingReverseProxy = false

const getLocalCommonTrackingParams = (): Record<string, any> => {
  const now = Date()
  const result: Record<string, any> = {
    PageURL: window.location.href,
    PageDomain: window.location.hostname,
    PagePath: window.location.pathname,
    PageLocation: window.location.origin,
    PageTitle: document.title,
    using_reverse_proxy: usingReverseProxy,
    referer: document.referrer,
    version: process.env.NEXT_PUBLIC_BUILD_ID,
    utc_date: utcDate(now),
    utc_time: utcTime(now),
    utc_datetime: utcDateTime(now),
  }
  try {
    const uid = localStorage.getItem(AMPLITUDE_USER_ID_KEY)
    if (uid) {
      result.uid = uid
    }

    const userCreateTime = localStorage.getItem(AMPLITUDE_USER_CREATE_TIME_KEY)
    if (userCreateTime) {
      const dateObj = dayjs(userCreateTime)
      result.user_create_time = utcDateTime(dateObj)
      result.user_create_date = utcDate(dateObj)
      result.is_new_user = utcDate(dateObj) === utcDate(now)
    }

    for (const utmKey of UTM_FIELDS) {
      const value = localStorage.getItem(utmKey)
      if (value) {
        result[utmKey] = value
      }
    }
  } catch (error) {
    return {}
  }
  return result
}

const setupAmplitude = async () => {
  try {
    await axios.post('https://api2.amplitude.com/2/httpapi', {
      api_key: AMPLITUDE_API_KEY,
      events: [],
      client_upload_time: new Date().toISOString(),
      options: {},
    })
  } catch (error) {
    whisper('Failed to ping Amplitude API', error)
    usingReverseProxy = true
  }

  const uid = localStorage.getItem(AMPLITUDE_USER_ID_KEY) ?? ''
  init(AMPLITUDE_API_KEY, uid, {
    defaultTracking: {
      sessions: true,
    },
    ...(usingReverseProxy
      ? {
          serverUrl: 'https://revproxy.haiper.ai/amp/2/httpapi',
        }
      : {}),
  })
}

let amplitudeSetupPromise: Promise<void> | null = null
const waitAmplitude = async () => {
  if (!isProduction) {
    return
  }

  if (!amplitudeSetupPromise) {
    amplitudeSetupPromise = setupAmplitude()
  }
  await amplitudeSetupPromise
}

export const setUserId = (id: string | undefined) => {
  setAmplitudeUserId(id)
  if (id === undefined) {
    localStorage.removeItem(AMPLITUDE_USER_ID_KEY)
  } else {
    localStorage.setItem(AMPLITUDE_USER_ID_KEY, id)
  }
}

export const setUserCreateTime = (time: string) => {
  if (!time) {
    localStorage.removeItem(AMPLITUDE_USER_CREATE_TIME_KEY)
    return
  }
  localStorage.setItem(AMPLITUDE_USER_CREATE_TIME_KEY, time)
}

export const AmplitudeContext = createContext<{
  track: (eventName: string, eventProperties?: Record<string, any>) => void
  setUserId: (id: string) => void
  setUserCreateTime: (time: string) => void
}>({
  track: noop,
  setUserId,
  setUserCreateTime,
})

const AmplitudeContextProvider = ({ children }: any) => {
  useEffect(() => {
    waitAmplitude().catch(console.error)
  }, [])

  const commonTrackingParmas = useCommonEventTrackingParams()

  const trackAmplitudeEvent = useMemo(
    () => async (eventName: string, eventProperties?: Record<string, any>) => {
      await waitAmplitude()

      const finalEventProperties = {
        ...commonTrackingParmas,
        ...getLocalCommonTrackingParams(),
        ...eventProperties,
      }
      if (isProduction) {
        track(eventName, finalEventProperties)
      } else {
        if (process.env.NODE_ENV !== 'test') {
          whisper(
            '[Amplitude Event]',
            `(uid: ${getUserId()}):`,
            eventName,
            finalEventProperties,
          )
        }
      }
    },
    [commonTrackingParmas],
  )

  const contextValue = useMemo(() => {
    return {
      track: trackAmplitudeEvent,
      setUserId,
      setUserCreateTime,
    }
  }, [trackAmplitudeEvent])

  return (
    <AmplitudeContext.Provider value={contextValue}>
      {children}
    </AmplitudeContext.Provider>
  )
}

export default AmplitudeContextProvider
